import styled, { css } from "styled-components";
import {
  BLACK_BG,
  content,
  medium,
  mobileOnly,
  skew,
  WHITE_BG,
} from "../common/styles/mixins";
type SKEW_TYPE = {
  noContent?: boolean;
  angle?: string | number;
};
export const SkewedDiv = styled.div<SKEW_TYPE>`
  ${(props) => skew(props?.angle?.toString() || "-5deg", props.noContent)}
`;

export const WhiteSkewedDiv = styled(SkewedDiv)`
  background-color: ${WHITE_BG};
  color: ${BLACK_BG};
`;

export const Container = styled.div`
  min-height: calc(100vh - 100px);
  text-align: justify;
  h1 {
    text-align: center;
    font-size: 3em;
  }
  h2 {
    text-align: center;
    font-size: 2.5em;
  }
  h3 {
    ${mobileOnly(css`
      text-align: center;
      line-height: 2ex;
    `)}
    font-family: "Bebas Neue Pro Expanded";
    font-size: 2em;
    &.intro {
      text-align: center;
      margin-top: 0;
      ${mobileOnly(css`
        margin-top: unset;
      `)}
    }
  }
  p {
    margin: 1.2em 0;
  }
  .illustration_container {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    ${medium(css`
      flex-direction: row;
    `)}
    &.reversed {
      flex-direction: column-reverse;
      ${medium(css`
        flex-direction: row-reverse;
      `)}
    }
    ${medium(css`
      div:not(:only-child) {
        p {
          max-width: 60ch;
        }
      }
      div:only-child {
        p {
          max-width: 100ch;
        }
      }
    `)}
    div:first-child:not(:only-child) {
      justify-content: center;
      flex: 1;
      img {
        max-height: 450px;
        width: 100%;
        height: 100%;
        object-fit: contain;
        min-width: 200px;
      }
    }
  }
  .horizontal_container {
    display: flex;
    h3 {
      white-space: nowrap;
    }
    ${mobileOnly(css`
      flex-direction: column;
    `)}
    > div {
      text-align: center;
      flex: 1;
      & + div {
        ${medium(css`
          margin-left: 3em;
        `)}
      }
    }
  }
`;

export const Content = styled.div.attrs<any>({
  className: "content",
})<{ noPadding?: boolean }>`
  ${({ noPadding }) => content({ noPadding })}
  ${(props) => (props.noPadding ? "" : "padding-bottom: 5em")};
  text-align: center;
  h1 {
    text-transform: none;
    font-size: 2.5em;
  }
`;
