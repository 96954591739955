import styled, { css } from "styled-components";
import { glow } from "../../styles/mixins";
export const cover = css`
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: relative;
`;
export const Wrapper = styled.div`
  height: 0;
  width: 100%;
  position: relative;
  > div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    > div {
      height: inherit;
      width: 100%;
      display: flex;
      position: relative;
    }
  }
  video {
    ${cover}
  }
  ${glow()}
`;
