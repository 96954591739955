import { doc, getDoc, getDocs } from "@firebase/firestore";
import {
  Art,
  BaseAPI,
  CAROUSEL_ITEM,
  Collection,
  SpaceshipFirebase,
} from "./base";

export type MAIN_PAGE_CONFIG = {
  carousel: CAROUSEL_ITEM[];
  featured: (Collection & { arts: Art[] })[];
  how_to_buy?: {
    transfer_article: string;
    wallet_article: string;
  };
};

export type ABOUT_US_CONFIG = {
  title: string;
  subtitle: string;
  sections: Array<{ title: string; content: string }>;
  principles: string[];
};

const MAIN_PAGE = "mainpage";
const ABOUT_US = "about_us";
class Configs extends BaseAPI<any> {
  constructor(firestore: SpaceshipFirebase) {
    super(firestore, "configs");
  }
  aboutUs(locale: string) {
    return getDoc(doc(this.getCollection(), `${locale}.${ABOUT_US}`)).then(
      (snapshot) => {
        if (!snapshot.exists) return null;
        return snapshot.data() as ABOUT_US_CONFIG;
      }
    );
  }
  aboutUsRef(locale: string) {
    return doc(this.getCollection(), `${locale}.${ABOUT_US}`);
  }
  mainPage(locale: string) {
    return getDoc(doc(this.getCollection(), `${locale}.${MAIN_PAGE}`)).then(
      (snapshot) => {
        if (!snapshot.exists()) return null;
        return snapshot.data() as MAIN_PAGE_CONFIG;
      }
    );
  }
  mainPageRef(locale: string) {
    return doc(this.getCollection(), `${locale}.${MAIN_PAGE}`);
  }
}

export { Configs };
