import { HTMLAttributes } from "react";
import { Container } from "./styles";

type AVATAR_PROPS = {
  size?: number | string;
  src: string;
  style?: HTMLAttributes<HTMLDivElement>["style"];
  className?: string;
  wrapperClassName?: string;
  glow?: boolean | "on" | "off";
};

const Avatar: React.FC<AVATAR_PROPS> = ({
  size = 42,
  src,
  glow,
  className = "",
  wrapperClassName = "",
  ...props
}) => {
  return (
    <Container
      style={{
        height: size,
        width: size,
        minWidth: size,
        borderRadius: size,
        overflow: "hidden",
      }}
      className={[
        glow ? "glow" : "",
        glow === "off" ? "off" : "",
        className || "",
      ].join(" ")}
    >
      <img
        {...props}
        src={src}
        style={{
          height: "100%",
          width: "100%",
          objectFit: "cover",
          objectPosition: "center center",
          ...props.style,
        }}
      />
    </Container>
  );
};

export default Avatar;
