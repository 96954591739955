import React, { forwardRef, HTMLAttributes, useEffect, useState } from "react";
import { useImage } from "react-image";
import styled from "styled-components";
import { MediaComponent } from "../Media";
import { cover, Wrapper } from "./styles";

const Image = React.memo(styled(MediaComponent)`
  ${cover}
`);

type IMAGE_PROPS = {
  src: string;
  width?: number | string;
  aspectRatio?: number;
  glow?: boolean | "on" | "off";
  muted?: boolean;
  alt?: string;
  style?: any;
} & HTMLAttributes<HTMLImageElement>;

const SmartImage: React.FC<IMAGE_PROPS> = forwardRef<any, IMAGE_PROPS>(
  (props, ref) => {
    const {
      width,
      aspectRatio = 1,
      glow,
      className = "",
      muted = true,
      src,
      placeholder,
      alt,
      style = {},
    } = props;

    const paddingTop = (1 / aspectRatio) * 100 + "%";
    return (
      <div style={width ? { width, position: "relative" } : {}}>
        <Wrapper style={{ paddingTop, ...style }}>
          <div>
            <div>
              <Image
                {...{ src, muted, alt, placeholder }}
                ref={ref}
                className={[
                  className,
                  glow ? "glow" : "",
                  glow === "off" ? "off" : "",
                ].join(" ")}
              />
            </div>
          </div>
        </Wrapper>
      </div>
    );
  }
);
export default React.memo(SmartImage);
