import styled, { css } from "styled-components";
import { bottomText, medium, mobileOnly } from "../../styles/mixins";
const typography = css`
  h2 {
    font-size: 2.75em;
    margin: 0;
  }
  p {
    font-size: 0.84em;
    margin: 0;
  }
  div + p,
  h2 + p {
    margin-top: 1ch;
    ${medium(css`
      margin-top: 16px;
    `)}
  }
`;
export const CountdownWrapper = styled.div`
  ${typography}
  .countdown {
    display: flex;
    justify-content: space-between;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 4.2ch;
      ${medium(css`
        flex: 1;
        margin: 0px 1.25ch;
        &:first-child {
          margin-left: 0px;
        }
      `)}
      &:last-child {
        margin-right: 1ch;
      }
    }
    ${mobileOnly(css`
      h2 {
        margin: 0 !important;
      }
    `)}
  }
`;
export const BidWrapper = styled.div`
  display: flex;
  span {
    white-space: nowrap;
  }
  a {
    flex: 1;
  }
`;

export const CurrentBidWrapper = styled.div`
  ${typography}
  ${mobileOnly(css`
    > div {
      display: flex;
      & a {
        flex: 1;
        display: flex;
        margin-left: 1em;
      }
      & button {
        margin-left: 1em;
        font-size: 1.5em;
        margin-top: 10px;
        flex: 1;
      }
    }
  `)}
  ${medium(css`
    margin-right: 42px;
  `)}
`;

export const AuctionInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${medium(css`
    flex-direction: row;
  `)}
  ${mobileOnly(css`
    .countdown_wrapper {
      margin-top: 1.5em;
    }
  `)}
`;

export const BottomText = styled.div`
  ${bottomText()}
`;
