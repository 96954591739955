import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { FaEthereum } from "@react-icons/all-files/fa/FaEthereum";
import { useTimer } from "react-timer-hook";
import Button, { BUTTON_VARIANTS } from "../Button";
import { Art, RATES_TYPE } from "../../lib/api/base";
import { SPACESHIP_HASH } from "../../lib/constants";
import { useBreakpoint } from "../../lib/hooks/useWindow";
import { toDoubleDigits } from "../../lib/utils";
import {
  AuctionInfoWrapper,
  BidWrapper,
  BottomText,
  CountdownWrapper,
  CurrentBidWrapper,
} from "./styles";
type AUCTION_INFO_PROPS = {
  art: Art;
  crypto: Required<RATES_TYPE>;
  noWrapper?: true;
  className?: string;
};
type CURRENT_BID_PROPS = {
  art: Art;
  quote: number;
  className?: string;
  titleComponent?: React.FC;
  variant?: BUTTON_VARIANTS;
  locale?: string;
};
type COUNTDOWN_PROPS = {
  closing_date: string;
  title?: string | null;
  classNames?: string;
};
const removeDecimals = (str: string) => str.split(".")[0];
const fromWei = (num: string) => (+num / Math.pow(10, 18)).toString();
const getArtBidInfo = (art: Art) => {
  const t = useTranslations("Auction");
  const highestBid = art.highest_bid;
  const reserve = removeDecimals(art.reserve_price || "");
  const resultObj: {
    artEthPrice: string;
    artEthBidPrice: string;
    closing_date: string;
    hasMetReserve: boolean | null;
    symbol: string;
  } = {
    artEthPrice: "",
    artEthBidPrice: "",
    closing_date: "",
    hasMetReserve: null,
    symbol: "",
  };
  // Set reserve price
  if (reserve) {
    resultObj.artEthPrice = fromWei(reserve);
    resultObj.symbol = "ETH";
  } else {
    resultObj.artEthPrice = t("no_reserve") as string;
  }
  // If item is not listed, return with just this information
  if (!art.sell_orders || art.sell_orders.length === 0) return resultObj;
  // If item is listed we have currency and closing date
  resultObj.closing_date = art.sell_orders?.[0]?.closing_date;
  resultObj.symbol = art.sell_orders[0].payment_token_contract.symbol;
  const hasMetReserve = highestBid
    ? +fromWei(reserve) < +fromWei(highestBid.current_price)
    : null;
  resultObj.hasMetReserve = hasMetReserve;
  // If there's a bid
  if (highestBid) {
    // If it doesnt meet reserve
    if (!hasMetReserve) {
      resultObj.artEthBidPrice = fromWei(
        removeDecimals(highestBid.current_price)
      );
      // If it meets reserve
    } else {
      resultObj.artEthPrice = fromWei(removeDecimals(highestBid.current_price));
    }
  }
  return resultObj;
};

const Countdown = ({
  closing_date,
  title: propsTitle,
  classNames = "",
}: COUNTDOWN_PROPS) => {
  const t = useTranslations("Auction");
  const title = propsTitle || t("ending");
  const { seconds, minutes, hours, days, start } = useTimer({
    // @ts-ignore
    expiryTimestamp: new Date(closing_date),
  });
  useEffect(() => start(), []);
  return (
    <CountdownWrapper className={classNames}>
      {title && <p>{title}</p>}
      <div className="countdown">
        {days > 0 && (
          <div>
            <h2>{toDoubleDigits(days)}</h2>
            <p>{t("countdown.day")}</p>
          </div>
        )}
        <div>
          <h2>{toDoubleDigits(hours)}</h2>
          <p>{t("countdown.hour")}</p>
        </div>
        <div>
          <h2>{toDoubleDigits(minutes)}</h2>
          <p>{t("countdown.minute")}</p>
        </div>
        <div>
          <h2>{toDoubleDigits(seconds)}</h2>
          <p>{t("countdown.second")}</p>
        </div>
      </div>
    </CountdownWrapper>
  );
};
const DefaultTitle: React.FC = (props) => <p {...props} />;
const CurrentBid: React.FC<CURRENT_BID_PROPS> = ({
  art,
  quote,
  titleComponent = DefaultTitle,
  variant = "black-filled",
  locale,
}) => {
  const Title = titleComponent;
  const t = useTranslations("Auction");
  const isMedium = useBreakpoint("MEDIUM");
  const { artEthBidPrice, artEthPrice, hasMetReserve, symbol } =
    getArtBidInfo(art);
  const artPrice = !isNaN(+artEthPrice) && (+artEthPrice * quote).toFixed(2);
  const content = (
    <>
      <BottomText>
        {hasMetReserve === false && (
          <span style={{ whiteSpace: "nowrap" }}>
            {t("current")}: {artEthBidPrice}
            <FaEthereum />
          </span>
        )}
        {artEthPrice !== t("no_reserve") ? (
          <h2>
            {artEthPrice} {symbol}
          </h2>
        ) : (
          <p>{t("no_reserve")}</p>
        )}
      </BottomText>
      {artPrice && (
        <p>
          {locale && locale === "pt" && "R"}${artPrice}
        </p>
      )}
    </>
  );
  return (
    <CurrentBidWrapper>
      <Title>{t(hasMetReserve ? "current" : "reserve")}</Title>
      {isMedium ? (
        content
      ) : (
        <BidWrapper>
          <div>{content}</div>
          <a
            href={art.permalink + `?ref=${SPACESHIP_HASH}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            <Button
              title={t(art.reserve_price ? "bid" : "offer") as string}
              variant={variant}
            />
          </a>
        </BidWrapper>
      )}
    </CurrentBidWrapper>
  );
};
const AuctionInfo: React.FC<AUCTION_INFO_PROPS> = ({
  art,
  crypto,
  noWrapper,
}) => {
  const { locale } = useRouter();
  const content = (
    <>
      <CurrentBid
        {...{
          art,
          quote: crypto.ETH.quote[locale === "pt" ? "BRL" : "USD"].price,
          locale,
        }}
      />
      {art.sell_orders?.[0].closing_date && (
        <Countdown closing_date={art.sell_orders?.[0].closing_date} />
      )}
    </>
  );
  if (noWrapper) return content;
  return <AuctionInfoWrapper>{content}</AuctionInfoWrapper>;
};
export { AuctionInfo, CurrentBid, Countdown };
