import { getAuth, signInAnonymously } from "@firebase/auth";
import { getFirestore, onSnapshot, Query } from "@firebase/firestore";
import { useRouter } from "next/router";
import { useEffect, useMemo, useRef, useState } from "react";
import { BaseAPI } from "../api/base";
import { makeFirebase } from "../makeFirebase";
type REALTIME_RETURN<T> = {
  data: T;
  ref: Query<any>;
};

type USE_REALTIME_OVERWRIDES = {
  <T>(path: string): REALTIME_RETURN<T | undefined>;
  <T>(path: string, defaultVal: T): REALTIME_RETURN<T>;
};
// Add check for authed => onAuthStateChanged + snapshot
// @ts-ignore
const useRealtime: USE_REALTIME_OVERWRIDES = (path: any, defaultVal: any) => {
  const [data, setData] = useState(defaultVal);
  const firebase = makeFirebase();
  const store = getFirestore(firebase);
  const auth = getAuth(firebase);
  const api = new BaseAPI(store);
  const itemRef = useMemo(() => api.getFromPath(path), [path]);
  const [isLoggedIn, setIsLoggedIn] = useState(!!auth.currentUser);
  const timeout = useRef<NodeJS.Timeout | null>();
  const { locale } = useRouter();
  useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      setIsLoggedIn(!!user);
      if (!user) {
        timeout.current = setTimeout(() => {
          signInAnonymously(auth).catch((e) => {
            console.error(e);
            if (defaultVal) setData(defaultVal);
          });
        }, 1000);
      } else if (timeout.current) {
        clearTimeout(timeout.current);
      }
    });
  }, [timeout]);
  useEffect(() => {
    if (!isLoggedIn) return;
    return onSnapshot(
      itemRef,
      (snap) => {
        if ("data" in snap) {
          // @ts-ignore
          const item = snap.data();
          if (item && item.overrides) {
            setData({ ...item, ...item?.overrides?.[locale || "en"] });
          } else {
            setData(item);
          }
        } else {
          setData(
            snap.docs.map((doc: any) => {
              const item = doc.data();
              if (item && item.overrides) {
                return { ...item, ...item?.overrides?.[locale || "en"] };
              }
              return item;
            })
          );
        }
      },
      (err) => {
        console.error(err);
        if (defaultVal) setData(defaultVal);
      }
    );
  }, [path, isLoggedIn]);
  return { data: data || defaultVal, ref: itemRef };
};

export { useRealtime };
