import React, { useState, useEffect } from "react";
import { useImage } from "react-image";
import { isVideo } from "../../lib/utils";

type MEDIA_COMPONENT_PROPS = {
  src: string;
  muted?: boolean;
  className?: string;
  onClick?: () => any;
  lazy?: true;
  alt: string;
  placeholder?: string;
  placeholderStyle?: any;
  [key: string]: any;
};

const MediaComponent = React.forwardRef<any, MEDIA_COMPONENT_PROPS>(
  (
    { src, muted = true, className, onClick, lazy, placeholder, ...rest },
    ref
  ) => {
    const [finalSrc, setFinalSrc] = useState(placeholder || src);
    const isLoading = placeholder && finalSrc === placeholder;
    useEffect(() => {
      if (finalSrc === placeholder && !isVideo(src)) {
        const img = new Image();
        img.onload = () => {
          setFinalSrc(src);
        };
        img.src = src;
        return () => {
          img.onload = null;
        };
      }
    }, []);
    return isVideo(src) ? (
      <video
        src={src}
        ref={ref}
        className={className}
        onClick={onClick}
        muted={muted}
        autoPlay
        playsInline
        loop
        {...rest}
      />
    ) : (
      <img
        src={finalSrc}
        ref={ref}
        className={className}
        onClick={onClick}
        loading={!lazy ? undefined : "lazy"}
        {...rest}
      />
    );
  }
);

export { MediaComponent };
