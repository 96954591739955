import { useTranslations } from "use-intl";
import Link from "next/link";
import { Art, RATES_TYPE } from "../../lib/api/base";
import { useRealtime } from "../../lib/hooks/useRealtime";
import { useBreakpoint } from "../../lib/hooks/useWindow";
import {} from "../../styles/mixins";
import SmartImage from "../SmartImage";
import { getArtUrl } from "../../lib/utils";
import { Glass } from "../Carousel/style";
import Avatar from "../Avatar";
import { SPACESHIP_HASH } from "../../lib/constants";
import Button from "../Button";
import { AuctionInfo } from "../AuctionInfo";
type AUCTION_PROPS = {
  art: Art;
  crypto: Required<RATES_TYPE>;
  styles: any;
  t: ReturnType<typeof useTranslations>;
};

const IMAGE_SIZE_MEDIUM = 450;

const CurrentAuction: React.FC<AUCTION_PROPS> = ({
  art: propsArt,
  crypto,
  styles,
  t,
}) => {
  const isMedium = useBreakpoint("MEDIUM");
  const { data: featuredArt } = useRealtime<Art>(
    `arts/${propsArt?.id || 0}`,
    propsArt
  );

  if (!propsArt) return null;
  const art = featuredArt || propsArt;
  const { name } = art;
  return (
    <div className={styles.auction_wrapper}>
      <div
        style={
          isMedium
            ? {
                paddingLeft: 8,
                flex: 1,
                width: IMAGE_SIZE_MEDIUM,
                maxWidth: IMAGE_SIZE_MEDIUM,
                // height: 500,
              }
            : {
                flex: 1,
              }
        }
      >
        <Link href={`/arts/${art.slug}`}>
          <a>
            <SmartImage
              src={getArtUrl(art)}
              width={"100%"}
              className="off"
              style={{
                borderRadius: 6,
                border: "3px solid black",
                overflow: "hidden",
              }}
            />
          </a>
        </Link>
      </div>
      <Glass
        className={styles.art_info}
        style={{ backgroundColor: "rgba(0 0 0 / 5%)" }}
      >
        <Link href={`/artists/${art.collection.slug}`}>
          <a>
            <div className={styles.avatar_wrapper}>
              <Avatar
                src={art.collection.image_url}
                size={isMedium ? 52 : 42}
              />
              <div className={styles.artist_name_wrapper}>
                <span>{t("created_by")}</span>
                <span className={styles.artist_name}>
                  {art.collection.name}
                </span>
              </div>
            </div>
          </a>
        </Link>

        <div>
          <h2 className={styles.art_title}>{name}</h2>
        </div>
        <AuctionInfo {...{ art, crypto }} />
        <div className={styles.buttons}>
          {isMedium && art.sell_orders && (
            <a
              href={art.permalink + `?ref=${SPACESHIP_HASH}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              <Button
                title={t("bid") as string}
                variant="black-filled"
                className={styles.first}
              />
            </a>
          )}
          <Link href={`/arts/${art.slug}`}>
            <Button
              title={t("view") as string}
              variant="black-filled"
              className={styles.second}
            />
          </Link>
          <Link href={`/artists/${art.collection.slug}`}>
            <Button
              title={t("about") as string}
              variant="black-filled"
              className={styles.third}
            />
          </Link>
        </div>
      </Glass>
    </div>
  );
};

export { CurrentAuction };
