import styled from "styled-components";
import { content, glass, WHITE_BG } from "../common/styles/mixins";

export const PADDING_BOTTOM = "2em";

export const Content = styled.div.attrs(() => {
  return { className: "content" };
})`
  padding-bottom: ${PADDING_BOTTOM};
  .slick-list {
    padding-bottom: 14px;
  }
  .slick-dots {
    li button::before {
      color: ${WHITE_BG};
      font-size: 14px;
    }
  }
`;

export const Glass = styled.div`
  ${glass("1.5em")}
  h2 {
    margin-top: 0;
  }
`;
