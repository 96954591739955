import styled, { css } from "styled-components";
import { content, glass, medium, mobileOnly } from "../../styles/mixins";

export const Glass = styled.div.attrs({ className: "glass" })`
  ${glass()}
  ${mobileOnly(css`
    width: 100%;
    backdrop-filter: blur(1px);
    border-radius: 6px;
  `)}
  h2 {
    text-transform: none;
    margin-top: 0;
  }
  p {
    text-align: justify;
    &:last-child {
      margin-bottom: 0;
    }
  }
  width: fit-content;
  a {
    display: inline-block;
  }
  &:only-child {
    min-width: 50vw;
    margin: auto;
    ${medium(css`
      max-width: 75vw;
    `)}
  }
`;

export const ItemWrapper = styled.div`
  margin: auto;
  margin-top: 1em;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1em;
  position: relative;
  ${mobileOnly(css`
    flex-direction: column;
  `)}
  ${medium(css`
    width: fit-content;
    .glass {
      h2 {
        max-width: 25ch;
      }
      p {
        max-width: 45ch;
      }
    }
  `)}
`;
