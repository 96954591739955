import { getFirestore, setDoc } from "@firebase/firestore";
import { bulkFilter } from "bc-graphql-filter";
import { GetStaticProps } from "next";
import { withAuthUser } from "next-firebase-auth";
import { useTranslations } from "next-intl";
import { LogoJsonLd } from "next-seo";
import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { ISourceOptions } from "react-tsparticles";
import { useMeasure } from "react-use";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useForm, usePlugin } from "tinacms";
import Avatar from "../common/components/Avatar";
import Banner from "../common/components/Banner";
import Button from "../common/components/Button";
import { Carousel } from "../common/components/Carousel";
import { CurrentAuction } from "../common/components/CurrentAuction";
import Header from "../common/components/Header";
import SmartImage from "../common/components/SmartImage";
import {
  Art,
  Collection,
  LOCALE_OPTIONS,
  RATES_TYPE,
} from "../common/lib/api/base";
import { Configs, MAIN_PAGE_CONFIG } from "../common/lib/api/configs";
import { getCachedRates } from "../common/lib/getCryptoRates";
import { useBreakpoint } from "../common/lib/hooks/useWindow";
import { makeFirebase } from "../common/lib/makeFirebase";
import {
  cs,
  getArtLowQualUrl,
  getArtPreviewUrl,
  removeInvalidValues,
  toDocs,
} from "../common/lib/utils";
import particleConfig from "../common/particlesjs-config.json";
import {
  HEADER_HEIGHT_MEDIUM,
  HEADER_HEIGHT_MOBILE,
} from "../common/styles/mixins";
import {
  FEATURED_ART_FORM_RESULT,
  makeMainPageForm,
} from "../forms/mainPageForm";
import { WhiteSkewedDiv } from "../styles/About";
import { Content, PADDING_BOTTOM } from "../styles/Home";
import styles from "../styles/Home.module.scss";

const DynamicParticles = dynamic(() => import("react-tsparticles"), {
  ssr: false,
});
//
const IMAGES_PER_ARTIST = 3;

const HOW_TO_BUY_SECTIONS = [
  {
    name: "setup",
    hideButton: false,
  },
  {
    name: "fund",
    hideButton: true,
  },
  {
    name: "transfer",
    hideButton: false,
  },
] as const;

type HOME_PROPS = {
  collections: Collection[];
  featuredArts: Art[];
  crypto: Required<RATES_TYPE>;
  admin: {
    arts: Art[];
  } | null;
  config: MAIN_PAGE_CONFIG;
  auctionArt: Art;
};

const FeaturedArtists: React.FC<
  Pick<HOME_PROPS, "collections" | "featuredArts">
> = React.memo(({ collections, featuredArts }) => {
  const t = useTranslations("Home");
  const isMedium = useBreakpoint("MEDIUM");
  return (
    <div className={styles.featured_wrapper}>
      <h2>{t("featured")}</h2>
      {collections.map(({ slug, name, image_url }, i) => {
        const arts = featuredArts.slice(
          IMAGES_PER_ARTIST * i,
          IMAGES_PER_ARTIST * (i + 1)
        );
        return (
          <div key={slug}>
            <div className={cs(styles, ["artist_info_container"])}>
              <div className={styles.artist_info}>
                <Avatar
                  src={image_url}
                  size={isMedium ? 52 : 42}
                  glow={"off"}
                  className={styles.avatar}
                />
                <div className={styles.names}>
                  <Link href={`/artists/${slug}`}>
                    <a>
                      {name.split(" ").map((frag, i) => (
                        <h2 key={`${i}-${name}`}>{frag}</h2>
                      ))}
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className={styles.image_wrapper}>
              {arts.slice(0, 3).map((art) => {
                const { name } = art;
                return (
                  <div key={art.slug}>
                    <Link href={`/arts/${art.slug}`}>
                      <a>
                        <div>
                          <SmartImage
                            src={getArtPreviewUrl(art)}
                            placeholder={getArtLowQualUrl(art)}
                            width={"100%"}
                            glow={"off"}
                            alt={art.alt_text}
                          />
                        </div>
                        <p>{name}</p>
                      </a>
                    </Link>
                  </div>
                );
              })}
            </div>
            <div className={styles.button_wrapper}>
              <Link
                href={{
                  pathname: `/arts`,
                  query: {
                    artists: JSON.stringify([slug]),
                  },
                }}
              >
                <Button title={t("more") as string} variant="white" />
              </Link>
            </div>
          </div>
        );
      })}
      {/* <div>
        <Link href={`/arts`}>
          <a style={{ display: "block" }}>
            <Button
              title={t("full_gallery") as string}
              variant="white-filled"
              style={{ margin: "auto", fontSize: "1.25em" }}
            />
          </a>
        </Link>
      </div> */}
    </div>
  );
});

const artFragment = `
id
name
alt_text
slug
image_url
image_preview_url
image_thumbnail_url
image_original_url
animation_url
animation_original_url
animation_preview_url
permalink
`;

export const getStaticProps: GetStaticProps = async (ctx) => {
  const { makeAdmin } = await import("../common/lib/makeAdmin");
  const firebase = await makeAdmin();
  const firestore = firebase.firestore();
  const col = firestore.collection("collections");
  const arts = firestore.collection("arts");
  const cfgs = firestore.collection("configs");

  const locale = (ctx.locale || "en") as LOCALE_OPTIONS;
  const [collections, crypto, config, [auctionArt]] = await Promise.all([
    toDocs<Collection>(
      col.where(`overrides.${locale}.featured`, "==", true).get()
    ),
    getCachedRates(),
    cfgs
      .doc(`${locale}.mainpage`)
      .get()
      .then((r) => r.data()),
    toDocs<Art>(
      arts
        .where(`overrides.${locale}.mainpage_auction`, "==", true)
        .limit(1)
        .get()
    ),
  ]);
  const featuredArts = await Promise.all(
    collections.map(({ slug: id }) => {
      return toDocs<{ id: string }>(
        col
          .doc(id)
          .collection("featured_arts")
          .orderBy("order", "asc")
          .limit(IMAGES_PER_ARTIST)
          .get()
      ).then((items) => {
        const ids = items.map(({ id }) => id);
        return toDocs<Art>(arts.where("id", "in", ids).get()).then((arts) => {
          return ids.map((id) => {
            const art = arts.find(({ id: artId }) => artId === id) as Art;
            if (art) return { ...art, ...art.overrides?.[locale] };
            return null;
          });
        });
      });
    })
  );
  const arr = [] as Array<typeof featuredArts[number]>;
  const returnObj = {
    props: {
      collections: collections.map((col) => ({
        ...col,
        ...col.overrides?.[locale],
      })),
      crypto,
      config,
      featuredArts: arr.concat(...featuredArts),
      auctionArt: auctionArt
        ? {
            ...auctionArt,
            ...auctionArt.overrides?.[locale],
          }
        : null,
    },
  };
  try {
    return {
      props: await bulkFilter(returnObj.props, {
        collections: `
        {
          slug
          name
          image_url
        }`,
        config: `
        {
          carousel {
            type
            content {
              title
              description
              article_link
              image_link
            }
          }
          how_to_buy {
            transfer_article
            wallet_article
          }
        }`,
        crypto: `
        {
          ETH {
            quote {
              USD {
                price
              }
              BRL {
                price
              }
            }
          }
        }`,
        featuredArts: `
        {
          ${artFragment}
        }`,
        auctionArt: `
        {
          name
          slug
          collection {
            name
            slug
            image_url
          }
          image_url
          image_preview_url
          image_thumbnail_url
          animation_url
          animation_preview_url
          reserve_price
          sell_orders {
            payment_token_contract {
              symbol
            }
            closing_date
          }
          highest_bid {
            current_price
          }
        }
        `,
      }),
      revalidate: 60,
    };
  } catch (e) {
    console.error(e);
    return returnObj;
  }
};

const PINK_PIXEL =
  "data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM0/g8AAWsBNAUUB5MAAAAASUVORK5CYII=";

const Home: React.FC<HOME_PROPS> = (props) => {
  const isMedium = useBreakpoint("MEDIUM");
  const { locale } = useRouter();
  const tCarousel = useTranslations("Carousel");
  const t = useTranslations("Home");
  const { collections, crypto, admin, config, featuredArts, auctionArt } =
    props;
  const [data, form] = useForm<FEATURED_ART_FORM_RESULT>(
    makeMainPageForm(
      config,
      admin?.arts.map((art) => ({ label: art.name, value: art })) || [],
      {
        onSubmit: (formData) => {
          const cfgs = new Configs(getFirestore(makeFirebase()));
          return setDoc(
            cfgs.mainPageRef(locale || "en"),
            removeInvalidValues({
              carousel: formData.carousel.map((i) => removeInvalidValues(i)),
            }),
            {
              merge: true,
            }
          );
        },
      }
    )
  );
  usePlugin(form);
  const carouselData = data.carousel || config?.carousel || [];
  const [ref, dim] = useMeasure<any>();
  const { how_to_buy } = config;
  return (
    <>
      <LogoJsonLd
        logo={"https://spaceshipnft.com/logo.png"}
        url={"https://spaceshipnft.com"}
      />
      <Header />
      <Banner
        header
        ref={ref}
        style={{ marginBottom: `calc(-${PADDING_BOTTOM} + 32px)` }}
      >
        <Image
          src={"/galaxy_bg.jpg"}
          placeholder="blur"
          layout="fill"
          objectFit="cover"
          blurDataURL={PINK_PIXEL}
        />
        <DynamicParticles
          height={`${
            dim.height +
            (isMedium ? HEADER_HEIGHT_MEDIUM : HEADER_HEIGHT_MOBILE)
          }px`}
          id="tsparticles"
          options={particleConfig as unknown as ISourceOptions}
        />
        <Content>
          <div style={{ width: "90%", margin: "auto" }}>
            <Carousel {...{ data: carouselData, crypto, styles }} />
          </div>
        </Content>
        <div className="shadow" />
      </Banner>
      {auctionArt && (
        <WhiteSkewedDiv
          angle={"0deg"}
          style={{ padding: "2em 0" }}
          className={styles.featured_auction}
        >
          <div>
            <h2>{t("auctioned")}</h2>
            <div>
              <CurrentAuction
                {...{ art: auctionArt, t: tCarousel, crypto, styles }}
              />
            </div>
          </div>
        </WhiteSkewedDiv>
      )}
      <main>
        <FeaturedArtists {...{ collections, featuredArts }} />
        {how_to_buy && (
          <div
            className={styles.how_to_buy}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div>
              <h2>{t("how_to_buy")}</h2>
            </div>
            <div className={styles.options}>
              {HOW_TO_BUY_SECTIONS.map((section) => {
                const sectionName = section.name;
                return (
                  <div key={sectionName}>
                    <h3>{t(`${sectionName}.title`)}</h3>
                    <p>
                      {t(`${sectionName}.description`, {
                        walletArticle: (str) => (
                          <Link href={`/news/${how_to_buy.wallet_article}`}>
                            <a>{str}</a>
                          </Link>
                        ),
                        transferArticle: (str) => (
                          <Link href={`/news/${how_to_buy.transfer_article}`}>
                            <a>{str}</a>
                          </Link>
                        ),
                        metamask: () => (
                          <a
                            href="https://metamask.io"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            MetaMask
                          </a>
                        ),
                        binance: (str) => (
                          <a
                            href={`https://www.binance.com/${locale}/buy-ethereum`}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            {str}
                          </a>
                        ),
                        coinbase: (str) => (
                          <a
                            href={`https://www.coinbase.com/${locale}/buy-ethereum`}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            {str}
                          </a>
                        ),
                        moonpay: () => (
                          <a
                            href={`https://www.moonpay.com/`}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            MoonPay
                          </a>
                        ),
                      })}
                    </p>
                    {/* {!section.hideButton && (
                      <div className={styles.button_wrapper}>
                        <Link href={`/`}>
                          <Button
                            title={t(`${sectionName}.button`) as string}
                            variant="white"
                          />
                        </Link>
                      </div>
                    )} */}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </main>
    </>
  );
};

export default withAuthUser<HOME_PROPS>()(Home);
