import styled from "styled-components";
import { content, glow, glowBoxShadow } from "../../styles/mixins";

const a = `@import "styles/mixins";`;

export const Container = styled.div`
  &.glow {
    ${glowBoxShadow()}
  }
`;
