import { useRouter } from "next/router";
import { FormOptions } from "tinacms";
import { Art } from "../common/lib/api/base";
import { MAIN_PAGE_CONFIG } from "../common/lib/api/configs";
import { CAROUSEL_INPUT_DATA } from "./plugins/CarouselSelect";

export type FEATURED_ART_FORM_RESULT = {
  // featured_art: FEATURED_ART_INPUT_DATA<Art>;
  carousel: CAROUSEL_INPUT_DATA;
};

type HOMEPAGE_FORM_RETURN_TYPE = FormOptions<FEATURED_ART_FORM_RESULT>;

const makeMainPageForm = (
  config: MAIN_PAGE_CONFIG,
  data: Array<{ label: string; value: Art }>,
  overrides: Partial<HOMEPAGE_FORM_RETURN_TYPE> = {}
): HOMEPAGE_FORM_RETURN_TYPE => {
  return {
    id: "",
    initialValues: config,
    fields: [
      {
        name: "carousel",
        component: "carousel-select",
        label: "Carrossel",
        data,
      },
    ],
    label: "Main page settings",
    onSubmit: console.log,
    ...overrides,
  };
};

export { makeMainPageForm };
