import { useTranslations } from "next-intl";
import Link from "next/link";
import SlickCarousel from "react-slick";
import { CAROUSEL_ITEM, RATES_TYPE } from "../../lib/api/base";
import { useBreakpoint } from "../../lib/hooks/useWindow";
import Button from "../Button";
import SmartImage from "../SmartImage";
import { Glass, ItemWrapper } from "./style";

const IMAGE_SIZE_MEDIUM = 450;

type CAROUSEL_ITEM_PROPS = {
  item: CAROUSEL_ITEM;
  crypto: Required<RATES_TYPE>;
  styles: any;
  t: ReturnType<typeof useTranslations>;
  hasImage: boolean;
};

const CarouselItem: React.FC<CAROUSEL_ITEM_PROPS> = ({
  item,
  styles,
  crypto,
  t,
  hasImage,
}) => {
  const isMedium = useBreakpoint("MEDIUM");
  const trackSize = isMedium && hasImage ? 380 : undefined;
  const content = (
    <Glass>
      <h2>{item.content.title}</h2>
      <p>{item.content.description}</p>
      {item.content.article_link && (
        <a href={item.content.article_link} target="_blank">
          <Button title={t("learn") as string} />
        </a>
      )}
    </Glass>
  );
  switch (item.type) {
    // case "art":
    //   return (
    //     <CurrentAuction {...{ art: item.content.art, crypto, styles, t }} />
    //   );
    case "image":
      return (
        <ItemWrapper>
          {isMedium && (
            <div
              style={{ height: IMAGE_SIZE_MEDIUM, width: IMAGE_SIZE_MEDIUM }}
            >
              <SmartImage src={item.content.image_link} glow={"off"} />
            </div>
          )}
          {content}
        </ItemWrapper>
      );
    case "post":
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: trackSize,
          }}
        >
          {content}
        </div>
      );
    default:
      return null;
  }
};

const SLICK_SETTINGS = {
  arrows: false,
  fade: true,
  infinite: true,
  speed: 500,
  autoplaySpeed: 7000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  dots: true,
};

type CAROUSEL_PROPS = {
  data: CAROUSEL_ITEM[];
  crypto: Required<RATES_TYPE>;
  styles: any;
};

const Carousel: React.FC<CAROUSEL_PROPS> = ({ data, crypto, styles }) => {
  const isMedium = useBreakpoint("MEDIUM");
  const t = useTranslations("Carousel");
  const hasImage = true; // data.some((i) => i.type === "image");
  if (isMedium) {
    return (
      <SlickCarousel {...SLICK_SETTINGS}>
        {data.map((item, i) => {
          return (
            <div key={i} style={{ height: "100%" }}>
              <CarouselItem {...{ item, crypto, styles, t, hasImage }} />
            </div>
          );
        })}
      </SlickCarousel>
    );
  }
  return <CarouselItem {...{ item: data[0], crypto, styles, t, hasImage }} />;
};

export { Carousel };
