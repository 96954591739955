import styled, { css } from "styled-components";
import {
  HEADER_HEIGHT_MOBILE,
  px,
  medium,
  HEADER_HEIGHT_MEDIUM,
  BLACK_BG,
} from "../../styles/mixins";
const Banner = styled.section<{ header?: boolean }>`
  /* Adjustment for header */
  ${(props) =>
    props.header
      ? css`
          margin-top: -${px(HEADER_HEIGHT_MOBILE)};
          padding-top: ${px(HEADER_HEIGHT_MOBILE)};
          ${medium(css`
            padding-top: ${px(HEADER_HEIGHT_MEDIUM)};
            margin-top: -${px(HEADER_HEIGHT_MEDIUM)};
          `)}
        `
      : ""}
  position: relative;
  ${medium(css`
    font-size: 1.25em;
  `)}
  z-index: 0;
  isolation: isolate;
  h2 {
    font-size: 2em;
  }
  #tsparticles,
  #tsparticles-2 {
    opacity: 0.6;
    inset: 0;
    position: absolute;
    z-index: 1;
  }
  .content {
    z-index: 3;
    position: relative;
  }
  .shadow {
    pointer-events: none;
    position: absolute;
    inset: 0;
    z-index: 2;
    background: linear-gradient(
      to top,
      ${BLACK_BG} 15px,
      transparent 60px,
      transparent ${(props) => (props.header ? "calc(100% - 140px)" : "50px")},
      ${BLACK_BG.replace("100%", "95%")} calc(100% - 70px),
      ${BLACK_BG}
    );
  }
  img {
    object-position: bottom;
  }
`;

export default Banner;
